import "./App.less";
import "../src/css/_style.scss";

import { Col, Layout, Menu } from "antd";

import ComingSoon from "./components/ComingSoon/comingsoon";
import ComingsSoon from "./components/ComingSoon/comingsoon";
import Cpu from "./components/cpu/cpu";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import { I18nextProvider } from "react-i18next";
import { MenuOutlined } from '@ant-design/icons';
import NavBar from "./components/navBar/navBar";
import Partnership from "./components/partnership/partnership";
import React from "react";
import Roadmap from "./components/roadmap/roadmap";
import Robots from "./components/robots/robots";
import Team from "./components/team/team";
import Tokenomics from "./components/tokenomics/tokenomics";
import common_en from "./translations/en/common-en.json";
import common_es from "./translations/en/common-es.json";
import i18next from "i18next";

const { Header } = Layout;

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    es: {
      common: common_es
    },
  },// React already does escaping
});

const { Content } = Layout;



function App() {


  return (
    <I18nextProvider i18n={i18next}>
      <Layout className="layout">
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <NavBar />
        </Header>
        <Content style={{ padding: "0 50px" }}>
          <div id="home" className="site-layout-content">
            <Home />
          </div>
          <Robots />
          <Cpu />
          <Tokenomics />
          <Roadmap />
          <Team />
          <Partnership />
          <Footer />
        </Content>
      </Layout>
    </I18nextProvider>
  );
}

export default App;
