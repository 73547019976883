/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import '../../css/_style.scss'

import React, { useEffect, useState } from 'react'
import { SoundFilled, SoundOutlined } from '@ant-design/icons'

import { Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import music from '../../img/cmusicrobot.wav'
import { useTranslation } from 'react-i18next'

const useAudio = (url) => {
  const [audio] = useState(new Audio(music))
  const [playing, setPlaying] = useState(false)
  const toggle = () => setPlaying(!playing)

  useEffect(() => {
    document.addEventListener('DOMContentLoaded', function (event) {
      setPlaying(true);
      audio.loop = true;
    }
    )
  })

  useEffect(() => {
    document.addEventListener('click', function (event) {
      audio.play()
    }
    )
  }, [])

  useEffect(() => {
    playing ? audio.play().catch(error => setPlaying(false)) : audio.pause()
  }, [playing])


  useEffect(() => {

    audio.addEventListener('ended', () => setPlaying(false))
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false))
    }
  }, [])

  return [playing, toggle]
}

function NavBar(url) {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      setScroll(
        document.body.scrollTop >= 150 ||
        document.documentElement.scrollTop >= 150,
      )
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [playing, toggle] = useAudio(url)
  const { t, i18n } = useTranslation('common')

  return (

    <div className={scroll ? 'scroll Nav' : 'Nav'} id="nav">
      <Menu mode="horizontal" overflowedIndicator={<MenuOutlined />} key='menu' className='menuLinks'>

        <Menu.Item key="1" idButton={'1'} onClick={toggle}>
          {playing ? <SoundFilled /> : <SoundOutlined />}
        </Menu.Item>

        <Menu.Item key="2"><a href="#">Home</a></Menu.Item>


        <Menu.Item key="3"><a href="#robots">Robots</a></Menu.Item>


        <Menu.Item key="4"><a href="#cpu">Cpu</a></Menu.Item>


        <Menu.Item key="5"><a href="#tokenomics">Tokenomics</a></Menu.Item>


        <Menu.Item key="6"><a href="#roadmap">RoadMap</a></Menu.Item>


        <Menu.Item key="7" onClick={() => { i18n.changeLanguage('en') }}>
          EN
        </Menu.Item>


        <Menu.Item key="8" onClick={() => i18n.changeLanguage('es')}>
          ES
        </Menu.Item>

      </Menu>
    </div >
  )
}

export default NavBar
