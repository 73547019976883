import "./style.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LogoFooter from "../../img/logoRobotech.svg";
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from "react-i18next";
function Footer() {
  const { t, i18n } = useTranslation('common');
  return (
    <div id="footer" className="footer">
      <div className='logoFooterContainer'><img className='logoFooter' src={LogoFooter} alt="logo" /></div>
      <span className='footerSpan'>{t('footer.span')}</span>
      <div className='rrssContainer'>
        <div className='rrssImg'> <a target="_blank" href="https://www.instagram.com/robotechwar/"><FontAwesomeIcon icon={faInstagram} /></a></div>
        <div className='rrssImg'> <a target="_blank" href="https://t.me/Robotechwargame"><FontAwesomeIcon icon={faTelegramPlane} /></a></div>
        <div className='rrssImg'> <a target="_blank" href="https://twitter.com/robotechwar"><FontAwesomeIcon icon={faTwitter} /></a></div>
      </div>
    </div>
  );
}

export default Footer;
