import "./style.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import chart from "../../img/tokenomicsChart.svg";
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import roadmap from "../../img/ROADMAP.svg";
import { useTranslation } from "react-i18next";

function Roadmap() {
  const { t, i18n } = useTranslation('common');
  return (
    <div id="roadmap" className="roadmap">
      <div className="titlesContainer"><img src={roadmap}></img></div>
      <div className='lineContainer'>
        <div className='lineTitle' /></div>
      <div className='phaseContainer'>
        <div className='phase'>
          <div><span className='roadmapTitle'>{t('roadmap.title1')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text1')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text2')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text3')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text4')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div> <span className='roadmapSpan'>{t('roadmap.text5')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div> <span className='roadmapSpan'>{t('roadmap.text6')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text7')}</span><FontAwesomeIcon icon={faCheck} /></div>
        </div>
        <div className='phase'>
          <div><span className='roadmapTitle'>{t('roadmap.title2')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text8')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text9')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text10')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text11')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div> <span className='roadmapSpan'>{t('roadmap.text12')}</span></div>
          <div> <span className='roadmapSpan'>{t('roadmap.text13')}</span></div>
          <div> <span style={{ marginLeft: "1rem" }} className='roadmapSpan'>{t('roadmap.text13a')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div> <span style={{ marginLeft: "1rem" }} className='roadmapSpan'>{t('roadmap.text13b')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div> <span style={{ marginLeft: "1rem" }} className='roadmapSpan'>{t('roadmap.text13c')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text14')}</span><FontAwesomeIcon icon={faCheck} /></div>
        </div>
      </div>
      <div className='phaseContainer' >
        <div className='phase'>
          <div><span className='roadmapTitle'>{t('roadmap.title3')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text15')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text16')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text161')}</span><FontAwesomeIcon icon={faCheck} /></div>
          <div><span className='roadmapSpan'>{t('roadmap.text17')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text18')}</span></div>
          <div> <span className='roadmapSpan'>{t('roadmap.text19')}</span></div>
          <div> <span className='roadmapSpan'>{t('roadmap.text191')}</span></div>
        </div>
        <div className='phase'>
          <div><span className='roadmapTitle'>{t('roadmap.title4')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text20')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text21')}</span></div>
          <div><span className='roadmapSpan'>{t('roadmap.text22')}</span></div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
