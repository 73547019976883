import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import centurion from "../../img/centurion.gif";
import cerberus from "../../img/cerberus.gif";
import patriot from "../../img/patriot.gif";
import predator from "../../img/predator.gif";
import robots from "../../img/ROBOTS.svg";
import spartan from "../../img/spartan.gif";
import spectrum from "../../img/spectrum.gif";
import { useTranslation } from "react-i18next";

function Robots() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="robots" className="robots">
      <div className="titlesContainer"><img src={robots}></img></div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="carrouselContainer">
        <Carousel
          showThumbs={false}
          showStatus={false}
          width="auto"
          swipeable={true}
          centerSlidePercentage="100%"
        >
          <div className="itemsCarrousel">
            <img className="nft" src={spectrum} alt="nft" />
            <div className="textContainerNft">
              <p className="robotsName">{t("robots.spectrum.name")} {t("robots.power")} 320 {t("robots.level")} 6</p>
              <p className="robotsSerie">{t("robots.spectrum.serie")}</p>
              <p className="robotsDescription">
                {t("robots.spectrum.description1")}
              </p>
              <p className="robotsDescription2">
                {t("robots.spectrum.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("robots.spectrum.link")}><p>{t("robots.spectrum.button")}</p></a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img className="nft" src={cerberus} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">{t("robots.cerberus.name")} {t("robots.power")} 250 {t("robots.level")} 5</p>
              <p className="robotsSerie">{t("robots.cerberus.serie")}</p>
              <p className="robotsDescription">
                {t("robots.cerberus.description1")}
              </p>
              <p className="robotsDescription2">
                {t("robots.cerberus.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("robots.cerberus.link")}><p>{t("robots.cerberus.button")}</p></a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img className="nft" src={predator} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">{t("robots.predator.name")} {t("robots.power")} 200 {t("robots.level")} 4</p>
              <p className="robotsSerie">{t("robots.predator.serie")}</p>
              <p className="robotsDescription">
                {t("robots.predator.description1")}
              </p>
              <p className="robotsDescription2">
                {t("robots.predator.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("robots.predator.link")}><p>{t("robots.predator.button")}</p></a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img className="nft" src={centurion} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">{t("robots.centurion.name")} {t("robots.power")} 150 {t("robots.level")} 3</p>
              <p className="robotsSerie">{t("robots.centurion.serie")}</p>
              <p className="robotsDescription">
                {t("robots.centurion.description1")}
              </p>
              <p className="robotsDescription2">
                {t("robots.centurion.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("robots.centurion.link")}><p>{t("robots.centurion.button")}</p></a>
              </div>
            </div>
          </div>
          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img className="nft" src={spartan} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">{t("robots.spartan.name")} {t("robots.power")} 100 {t("robots.level")} 2</p>
              <p className="robotsSerie">{t("robots.spartan.serie")}</p>
              <p className="robotsDescription">
                {t("robots.spartan.description1")}
              </p>
              <p className="robotsDescription2">
                {t("robots.spartan.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("robots.spartan.link")}><p>{t("robots.spartan.button")}</p></a>
              </div>
            </div>
          </div>

          <div className="itemsCarrousel">
            <div className="nftContainer">
              <img className="nft" src={patriot} alt="nft" />
            </div>
            <div className="textContainerNft">
              <p className="robotsName">{t("robots.patriot.name")} {t("robots.power")} 50 {t("robots.level")} 1</p>
              <p className="robotsSerie">{t("robots.patriot.serie")}</p>
              <p className="robotsDescription">
                {t("robots.patriot.description1")}
              </p>
              <p className="robotsDescription2">
                {t("robots.patriot.description2")}
              </p>
              <div className="buttonBuy">
                <a href={t("robots.patriot.link")}><p>{t("robots.patriot.button")}</p></a>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Robots;
