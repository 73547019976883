import "./style.scss";
import {useTranslation} from "react-i18next";
import Logo from "../../img/logoRobotech.svg";

function ComingSoon() {
  const {t, i18n} = useTranslation('common');
  return (
    <div id="home" className="home">
        <img className='logoImg' src={Logo} alt="logo" />
        <span className='comingText'>Coming Soon</span>
    </div>
  );
}

export default ComingSoon;
