import "./style.scss";

import Banner from 'react-js-banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from "../../img/logoRobotech.svg";
import auditLink from "../../img/ROBOTECHWAR-SmartContractAuditoria.pdf";
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from "react-i18next";

const badgeSystem = "/img/sponsors/system.jpeg";
const badgeDaddy = "/img/sponsors/daddy.jpeg";

function Home() {
  const { t, i18n } = useTranslation('common');
  const currentLanguage = i18n.language;

  return (
    <div id="home" className="home">
      <img className='logoImg' src={Logo} alt="logo" />

      <div className='buttonContainer'>
        <a href="https://youtu.be/lZsuKQSSle0"><div className='divBut'><p>{t('home.trailer')}</p></div></a>
        <a target="_blank" href={t('home.linkwhitePaper')}><div className='divBut'><p>{t('home.whitePaper')}</p></div></a>
        <a className='divBut' href="https://robotechwar.io/game/" ><p>{t('home.play')}</p></a>
        <a target="_blank" href="https://bscscan.com/token/0xb87c196b0720275bfdfb2a3a943172c321e793c4#balances"><div className='divBut'><p>{t('home.contract')}</p></div></a>
        <a target="_blank" href="https://robotechwar.io/audit/" download="Auditoria"><div className='divBut'><p>{t('home.audit')}</p></div></a>

      </div>
      <div className='rrssContainer'>
        <div className='rrssImg'> <a target="_blank" href="https://www.instagram.com/robotechwar/"><FontAwesomeIcon icon={faInstagram} /></a></div>
        <div className='rrssImg'> <a target="_blank" href=" https://t.me/ROBOTECHWAR"><FontAwesomeIcon icon={faTelegramPlane} /></a></div>
        <div className='rrssImg'> <a target="_blank" href="https://twitter.com/robotechwar"><FontAwesomeIcon icon={faTwitter} /></a></div>
      </div>
    </div>
  );
}


export default Home;
